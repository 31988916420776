(function () {
  'use strict';

  angular
    .module('events.vehicles.guests.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.vehicles.guests.edit', {
        url: '/:guestId/edit',
        templateUrl: 'events/vehicles/guests/edit/edit.tpl.html',
        controller: 'GuestEditCtrl',
        controllerAs: 'vm'
      })
      .state('events.vehicles.guests.add', {
        url: '/add',
        templateUrl: 'events/vehicles/guests/edit/edit.tpl.html',
        controller: 'GuestEditCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
